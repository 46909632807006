import "@fortawesome/fontawesome-free/css/all.css";
import "ol/ol.css";
import "/style.css";

import packageInfo from "./package.json";
document.getElementById("version").textContent =
  "Version: " + packageInfo.version;

import { getArea } from "ol/extent";
import { getArea as getGeometryArea } from "ol/sphere";

import { hidebasemaps, showgooglebasemap } from "./basemaplayer";
import { map } from "./globals";

import { generatelayerlist } from "./layerlist";
import {
  generateTechnicalDescriptions,
  generatecoords,
  generatefeature,
  plotfeatures,
  plotsubdivision,
} from "./technicaldescriptionmapping";

import {
  addDrawLayer,
  addtechnicaldescriptionlayer,
  getLayer,
} from "./vectorlayer";

import {
  addFeatureToLayer,
  enableDrawFeature,
  getDrawingData,
  enableEditMode,
  enableClearDrawFeature,
  getDrawingDataButton,
  enableGetCoordinatesButton,
  enableDragPolygon,
  addReprojectedFeatureToLayer,
  addLandParcelFeatureToLayer,
} from "./drawing";

import {
  enableParcelTaggingFeature,
  unbindParcelTaggingFeature,
  getDrawingDataMultipleFeature,
  startMultipleSelection,
  endMultipleSelection,
} from "./drawingsubdivision";

import {
  randomPolygonParcelStyle,
  enableSectionLegendList,
  handleSectionClick,
} from "./mapstyle";
import { Style, Fill, Stroke, Text } from "ol/style";
import Point from "ol/geom/Point";
// enableGetCoordinatesButton();
// const coords = [[125.12257453447955, 8.162556446426304],[125.12266895791703, 8.162231825505737]]
// const x = generateTechnicalDescriptions(coords);
// console.log(x);

window.enableeditmode = function () {
  enableEditMode();
};

window.enableedittechnicaldescriptionmode = function () {
  enableDragPolygon();
};

window.enablegetDrawingData = function () {
  getDrawingDataButton("single");
};

window.enablegetDrawingDataMultipleFeature = function () {
  getDrawingDataButton("multiple");
};

window.getGeoJSON = function () {
  const {
    geojson,
    geowkt,
    reprojectedgeojson,
    reprojectedgeowkt,
    projectioncode,
  } = getDrawingData();

  return JSON.stringify({
    geojson: JSON.stringify(geojson),
    geowkt: JSON.stringify(geowkt),
    projection: projectioncode,
    reprojectedgeojson: JSON.stringify(reprojectedgeojson),
    reprojectedgeowkt: JSON.stringify(reprojectedgeowkt),
  });
};

window.setgeometrytype = function (rputype) {
  // enabledownloadgeojson();
  switch (rputype) {
    case "land":
      enabletechnicaldescription();
      return;
    case "bldg":
      enablecapturebldg();
      return;
    case "mach":
      enablecapturemach();
      return;
    case "planttree":
      enablecaptureplanttree();
      return;
    case "misc":
      enablecapturemisc();
      return;
  }
};

function enabletechnicaldescription() {
  hidebasemaps();
  addtechnicaldescriptionlayer();
  generatelayerlist(map);
}

function enablecapturebldg() {
  showgooglebasemap();

  addDrawLayer();
  generatelayerlist(map);
  enableDrawFeature("Polygon", false);
  enableClearDrawFeature();
}

function enablecapturemach() {
  showgooglebasemap();

  addDrawLayer();
  generatelayerlist(map);
  enableDrawFeature("Point", true);
  enableClearDrawFeature();
}

function enablecaptureplanttree() {
  showgooglebasemap();

  addDrawLayer();
  generatelayerlist(map);
  enableDrawFeature("Point", true);
  enableClearDrawFeature();
}

function enablecapturemisc() {
  showgooglebasemap();

  addDrawLayer();
  generatelayerlist(map);
  enableDrawFeature("Point", true);
  enableClearDrawFeature();
}

window.rendergeojson = function (geojsonstring) {
  addFeatureToLayer(geojsonstring);
};

window.renderreprojectedgeojson = function (reprojectedgeojsonstring) {
  addReprojectedFeatureToLayer(reprojectedgeojsonstring);
};

window.renderlandparcel = function (geojsonstring, projection) {
  addLandParcelFeatureToLayer(geojsonstring, projection);
};

//render map from technical description land
window.rendermap = function (params) {
  const { tiepoint, technicaldescriptions } = JSON.parse(params);
  const { coords, projection } = generatecoords(
    tiepoint,
    technicaldescriptions
  );

  const {
    parcelwkt,
    parcelgeojson,
    parcelPolygonFeature,
    tieLineFeature,
    tiePointFeature,
  } = generatefeature(coords, projection, technicaldescriptions);

  plotfeatures(
    parcelPolygonFeature,
    tieLineFeature,
    tiePointFeature,
    projection
  );
  return JSON.stringify({
    coords: coords,
    geojson: parcelgeojson,
    geowkt: parcelwkt,
    projection: projection,
  });
};

window.generateClosePoint = function (params) {
  const { coords } = JSON.parse(params);
  coords.push(coords[1]);
  const technicalDescriptions = generateTechnicalDescriptions(coords);
  return JSON.stringify({ technicalDescriptions: technicalDescriptions });
};

window.rendersubdivision = function (params) {
  // console.log(params);

  // document.body.innerHTML = params;
  const { tiepoint, lots } = JSON.parse(params);

  // alert(JSON.stringify(lots));
  // var log = document.getElementById("log");
  // log.innerHTML = params;
  //  document.body.innerHTML = lots;
  var sectionlist = document.getElementById("sectionLegendList");
  sectionlist.innerHTML = "";
  
  // Get the layer and its source
  const subdivisionLayer = getLayer("New Feature");
  const source = subdivisionLayer.getSource();
  // Clear the source
  source.clear();

  //find fillColor for each section
  let sectionColor = {};
  lots.forEach((lot) => {
    if (lot.fillColor === undefined) {
      const { fillColor } = randomPolygonParcelStyle();
      if (!sectionColor[lot.section]){
        sectionColor[lot.section] = fillColor;
      }
      lot.fillColor = sectionColor[lot.section];
    }
  });

  // lots.forEach((lots) => {
  // const { fillColor } = randomPolygonParcelStyle();
  //   // enableSectionLegendList(section.section, fillColor);
  //   const subdivisionLayer = getLayer("New Feature");
  //   // subdivisionLayer.set(section.section, section.startparcelno);
  lots.forEach((lot) => {
    //  document.body.innerHTML = lot;
    // log.innerHTML = JSON.stringify(lot);
    const { coords, projection } = generatecoords(
      tiepoint,
      lot.technicaldescriptions
    );
    const {
      parcelwkt,
      parcelgeojson,
      parcelPolygonFeature,
      tieLineFeature,
      tiePointFeature,
    } = generatefeature(coords, projection, lot.technicaldescriptions);
    

    if (lot.section) {
      parcelPolygonFeature.set("section", lot.section);
      let params = {
        section: lot.section,
        startparcelno: lot.startparcelno,
        fillColor: lot.fillColor,
      };
      enableSectionLegendList(params);
      //disable click on labels sectionLegendList li
      var sections = document.querySelectorAll("#sectionLegendList li");
      sections.forEach(function (section) {
        section.classList.remove("active");
        section.removeEventListener("click", handleSectionClick);
      });
    }

    const sectionstyle = [
      new Style({
        fill: new Fill({
          color: lot.section ? lot.fillColor : "rgba(255, 204, 51, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 3,
        }),
      }),
    ];

    if (lot.parcelno) {
      parcelPolygonFeature.set("parcelno", lot.parcelno);
    }
    parcelPolygonFeature.set("cadastrallotno", lot.cadastrallotno);
    // parcelPolygonFeature.set("section", section.section);
    parcelPolygonFeature.set("objid", lot.objid);
    let geometry = parcelPolygonFeature.getGeometry();
    let coordinates = geometry.getCoordinates()[0];
    coordinates.forEach(function (coord, i) {
      if (lot.technicaldescriptions[i]) {
        var text =
          lot.technicaldescriptions[i].frompoint +
          "-" +
          lot.technicaldescriptions[i].topoint +
          "\n" +
          lot.technicaldescriptions[i].ns +
          " " +
          lot.technicaldescriptions[i].deg +
          "° " +
          lot.technicaldescriptions[i].min +
          "' " +
          lot.technicaldescriptions[i].ew +
          " " +
          lot.technicaldescriptions[i].distance +
          " m";

        var start = coordinates[i];
        var end = coordinates[(i + 1) % coordinates.length];

        // Calculate the midpoint between this coordinate and the next one
        var dx = end[0] - start[0];
        var dy = end[1] - start[1];
        var midpoint = [start[0] + dx / 2, start[1] + dy / 2];
        // Calculate the rotation from the positive x-axis to the line segment
        var rotation = Math.atan2(dy, dx);

        sectionstyle.push(
          new Style({
            geometry: new Point(midpoint),
            text: new Text({
              text: text,
              fill: new Fill({ color: "#000" }),
              stroke: new Stroke({ color: "#fff", width: 2 }),
              rotation: -rotation,
              offsetY: -15, // Adjust this value as needed
            }),
          })
        );
      }
    });

    sectionstyle.push(
      new Style({
        geometry: new Point(geometry.getInteriorPoint().getCoordinates()),
        text: new Text({
          text: `${lot.cadastrallotno}${
            lot.parcelno ? ` - Parcel No. ${lot.parcelno}` : ""
          }`,
          fill: new Fill({ color: "#000" }),
          stroke: new Stroke({ color: "#fff", width: 2 }),
        }),
      })
    );

    parcelPolygonFeature.setStyle(sectionstyle);

    plotsubdivision(
      parcelPolygonFeature,
      tieLineFeature,
      tiePointFeature,
      projection,
      sectionstyle
    );

    //break foreach loop here
    // throw BreakException;
  });

  //   });
};

window.enableParcelTaggingFeature = function () {
  // window.OlModel.hello("HELLO");
  enableParcelTaggingFeature();
};

window.startMultipleSelection = function () {
  //prompt section number and start parcel number
  startMultipleSelection();
};

window.endMultipleSelection = function () {
  //prompt section number and start parcel number
  endMultipleSelection();
};

window.enableAddSectionButton = function () {
  //add button to button group
  let buttonGroup = document.getElementById("buttonGroup");
  let button = document.createElement("button");
  button.innerHTML = "Add Section";
  button.onclick = function () {
    window.endMultipleSelection();
    window.startMultipleSelection();
  };
  buttonGroup.appendChild(button);
};

window.endParcelTaggingFeature = function (rputype) {
  // window.OlModel.hello("HELLO");
  unbindParcelTaggingFeature();
  const features = window.getGeoJSONSubdivision();
  return features;
};

window.getGeoJSONSubdivision = function () {
  const {
    geojson,
    geowkt,
    reprojectedgeojson,
    reprojectedgeowkt,
    projectioncode,
  } = getDrawingDataMultipleFeature();

  return JSON.stringify({
    geojson: JSON.stringify(geojson),
    geowkt: JSON.stringify(geowkt),
    projection: projectioncode,
    reprojectedgeojson: JSON.stringify(reprojectedgeojson),
    reprojectedgeowkt: JSON.stringify(reprojectedgeowkt),
  });
};

document.getElementById("burgerMenu").addEventListener("click", function () {
  const layerList = document.getElementById("layerList");
  if (layerList.classList.contains("hidden")) {
    layerList.classList.remove("hidden");
    layerList.style.display = "block";
  } else {
    layerList.classList.add("hidden");
    layerList.style.display = "none";
  }
});

document.getElementById("zoomtofeature").addEventListener("click", function () {
  zoomToLargestFeature();
});

function zoomToLargestFeature() {
  const layers = map.getLayers().getArray();
  let largestFeature = null;
  let largestFeatureArea = 0;

  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    const source = layer.getSource();
    if (source && typeof source.getFeatures === "function") {
      const features = source.getFeatures();
      for (let j = 0; j < features.length; j++) {
        const feature = features[j];
        const geometry = feature.getGeometry();
        const featureArea = getGeometryArea(geometry);
        if (featureArea > largestFeatureArea) {
          largestFeatureArea = featureArea;
          largestFeature = feature;
        }
      }
    }
  }

  if (largestFeature) {
    const extent = largestFeature.getGeometry().getExtent();
    map.getView().fit(extent, { duration: 1000 });
  } else {
    alert("No features found in any layer");
  }
}
