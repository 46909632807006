import { Overlay, View } from "ol";
import { unByKey } from "ol/Observable";
import { Draw, Modify, Snap } from "ol/interaction";
import { getArea } from "ol/sphere";
import { Style, Fill, Stroke, Text } from "ol/style";
import { map, proj4 } from "./globals";
import { getLayer } from "./vectorlayer";
import { Circle } from "ol/style";
import { GeoJSON, WKT } from "ol/format";
import { extend, createEmpty, getCenter } from "ol/extent";
import Translate from "ol/interaction/Translate";
import Collection from "ol/Collection";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import { generatelayerlist } from "./layerlist";
import { randomPolygonParcelStyle, enableSectionLegendList } from "./mapstyle";
import { DragBox } from "ol/interaction";
import { platformModifierKeyOnly } from "ol/events/condition";
import { getDrawingDataButton } from "./drawing";

const geoJSONFormat = new GeoJSON();
const wktFormat = new WKT();

let selected = null;
let originalStyle = null;
let startPoint, endPoint;

// Create a DragBox interaction
let dragBox = new DragBox({
  condition: platformModifierKeyOnly,
});

let clickHandler = null;
let startDragHandler = null;
let endDragHandler = null;
let availableParcelNumbers = {};
let currentStartParcelNo = {}; // Object to store current start parcel numbers for each section

export function getDrawingDataMultipleFeature() {
  const drawlayer = getLayer("New Feature");
  const features = drawlayer.getSource().getFeatures();

  features.forEach((feature) => {
    feature.set("geowkt", wktFormat.writeFeature(feature));
  });
  const geojson = geoJSONFormat.writeFeaturesObject(features);
  const geowkt = wktFormat.writeFeaturesText(features);

  // Get the projection of the features
  const projection = map.getView().getProjection();
  const projectioncode = projection.getCode();

  const reprojectedLayer = getLayer("Reprojected Feature");
  let reprojectedgeojson = null;
  let reprojectedgeowkt = null;
  if (reprojectedLayer) {
    const reprojectedFeatures = reprojectedLayer.getSource().getFeatures();
    reprojectedFeatures.forEach((feature) => {
      feature.set("geowkt", wktFormat.writeFeature(feature));
    });
    reprojectedgeojson = geoJSONFormat.writeFeaturesObject(reprojectedFeatures);
    reprojectedgeowkt = wktFormat.writeFeaturesText(reprojectedFeatures);
  }

  return {
    geojson,
    geowkt,
    reprojectedgeojson,
    reprojectedgeowkt,
    projectioncode,
  };
}

export function endMultipleSelection() {
  unByKey(map.on("click", clickHandler));
  // map.removeInteraction(dragBox);
  // unByKey(dragBox.on("boxstart", startDragHandler));
  // unByKey(dragBox.on("boxend", endDragHandler));
}

export function startMultipleSelection() {
  const section = getSectionNumber();
  if (section === null) return;

  const startparcelno = getStartParcelNumber();
  if (startparcelno === null) return;

  const { fillColor } = randomPolygonParcelStyle();
  const params = {
    section: section,
    startparcelno: startparcelno,
    fillColor: fillColor,
  };

  enableSectionLegendList(params);


  unByKey(map.on("click", clickHandler));
  clickHandler = function (evt) {
    addSelectedFeatureToSectionListHandler(evt, params);
  };
  map.on("click", clickHandler);
  //multi select feature like click and drag

  //   // Add the DragBox interaction to the map
  //   map.addInteraction(dragBox);

  //   startDragHandler = function (event) {
  //     startSectionDraggingHandler(event);
  //   };
  //   dragBox.on("boxstart", startDragHandler);

  //   endDragHandler = function (event) {
  //     endSectionDraggingHandler(event, params);
  //   };
  //   dragBox.on("boxend", endDragHandler);
}

function addSelectedFeatureToSectionListHandler(evt, params) {
  const { section, fillColor } = params;
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const features = drawSource.getFeatures();
   // If no startparcelno is provided, find the minimum parcel number for the section
   let startparcelno = params.startparcelno;
   if (!startparcelno) {
     let minParcelNo = Infinity;
     features.forEach((feature) => {
       const featureSection = feature.get("section");
       const featureParcelNo = feature.get("parcelno");
       if (featureSection === section && featureParcelNo) {
         minParcelNo = Math.min(minParcelNo, parseInt(featureParcelNo, 10));
       }
     });
     startparcelno = minParcelNo === Infinity ? 1 : minParcelNo; // Default to 1 if no parcel numbers are found
   }
 
  startparcelno = currentStartParcelNo[section] || startparcelno; // Use current start parcel number if available
  



  if (features.length === 0) {
    alert("No features found");
    return;
  }

  let selectedFeature = null;

  // Check for the feature at the clicked pixel
  map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    selectedFeature = feature;
    return true; // Stop iteration after the first feature is found
  });

  if (selectedFeature) {
    // Define the new fill color
    // Get the current style of the feature
    let originalStyle = selectedFeature.getStyle();

    // If the feature does not have a style, create a new one
    if (!originalStyle) {
      originalStyle = [
        new Style({
          fill: new Fill({
            color: fillColor,
          }),
          text: new Text({
            font: "12px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
          }),
        }),
      ];
    } else {
      // If the feature has a style, update the fill color
      originalStyle[0].setFill(
        new Fill({
          color: fillColor,
        })
      );

      // Ensure the Text style is initialized
      if (!originalStyle[0].getText()) {
        originalStyle[0].setText(
          new Text({
            font: "12px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
          })
        );
      }
    }

    const cadastrallotno = selectedFeature.get("cadastrallotno");
    const previousSection = selectedFeature.get("section");
    selectedFeature.set("section", section);
    selectedFeature.set("fillColor", fillColor);
    selectedFeature.set("startparcelno", startparcelno);
    let parcelno = selectedFeature.get("parcelno");
  
    // If the selected feature doesn't have a parcelno yet, or if it's an empty string, set it to a value
    if (!parcelno || parcelno === "") {
      parcelno = getNextAvailableParcelNo(features, section, startparcelno);
      alert(parcelno);
      originalStyle[originalStyle.length - 1]
        .getText()
        .setText(`${cadastrallotno} - Parcel No. ${parcelno}`);
      selectedFeature.set("parcelno", parcelno);
    } else {
      originalStyle[originalStyle.length - 1].getText().setText(cadastrallotno);
      originalStyle[0].setFill(new Fill({ color: "rgba(255, 204, 51, 0.2)" }));
      if (!availableParcelNumbers[previousSection]) {
        availableParcelNumbers[previousSection] = [];
      }
      availableParcelNumbers[previousSection].push(parcelno);
      selectedFeature.set("parcelno", "");
    }

    // Apply the new style to the selected feature
    selectedFeature.setStyle(originalStyle);
  
    // Ensure the map is re-rendered to reflect the changes
    map.render();

    // Update the current start parcel number for the section
    currentStartParcelNo[section] =
      getLastParcelNo(features, section, startparcelno) + 1;
  }
}

function getNextAvailableParcelNo(features, section, startparcelno) {
  console.log("available", availableParcelNumbers);

  // Check if there are available parcel numbers for the section
  if (
    availableParcelNumbers[section] &&
    availableParcelNumbers[section].length > 0
  ) {
    // Use the smallest available parcel number
    return availableParcelNumbers[section].sort((a, b) => a - b).shift();
  }

  // Find the maximum parcel number in the current section
  let maxParcelNo = startparcelno - 1; // Start from one less than the start parcel number
  features.forEach((feature) => {
    if (feature.get("section") === section) {
      const parcelno = parseInt(feature.get("parcelno"), 10);
      if (!isNaN(parcelno) && parcelno > maxParcelNo) {
        maxParcelNo = parcelno;
      }
    }
  });

  // Return the next available parcel number
  return maxParcelNo + 1;
}

function getLastParcelNo(features, section, startparcelno) {
  let maxParcelNo = startparcelno - 1; // Start from one less than the start parcel number
  features.forEach((feature) => {
    if (feature.get("section") === section) {
      const parcelno = parseInt(feature.get("parcelno"), 10);
      if (!isNaN(parcelno) && parcelno > maxParcelNo) {
        maxParcelNo = parcelno;
      }
    }
  });
  return maxParcelNo; // Return the last parcel number
}

function getSectionNumber() {
  const section = parseInt(prompt("Enter Section Number"), 10);
  if (!Number.isInteger(section)) {
    alert("Section is not an integer");
    return null;
  }
  return section;
}

function getStartParcelNumber() {
  const startparcelno = parseInt(prompt("Enter Start Parcel No."), 10);
  if (!Number.isInteger(startparcelno)) {
    alert("Start Parcel No. is not an integer");
    return null;
  }
  return startparcelno;
}

export function changeSectionSelection(params) {
  //unbind onclick on map first
  unByKey(map.on("click", clickHandler));
  clickHandler = function (evt) {
    addSelectedFeatureToSectionListHandler(evt, params);
  };
  map.on("click", clickHandler);
  // console.log(params);
  // Update the section value in the params
  //   const params = {
  //     section: selectedSectionValue,
  //     startparcelno: 1, // Example value, update as needed
  //     fillColor: 'rgba(0, 0, 255, 0.5)' // Example value, update as needed
  //   };

  //   // Call the feature selection handler with the updated params
  //   addSelectedFeatureToSectionListHandler(params);
}

function startSectionDraggingHandler(event) {
  startPoint = event.coordinate;
}

function endSectionDraggingHandler(event, params) {
  const { section, startparcelno, fillColor } = params;
  endPoint = event.coordinate;
  let extent = dragBox.getGeometry().getExtent();

  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const features = drawSource.getFeatures();
  if (features.length === 0) {
    alert("No features found");
    return;
  }

  let intersectingFeatures = [];
  drawSource.forEachFeatureIntersectingExtent(extent, function (feature) {
    let featureCenter = getCenter(feature.getGeometry().getExtent());
    intersectingFeatures.push({
      feature: feature,
      x: featureCenter[0],
    });
  });

  // Determine the direction of sorting
  let direction = startPoint[0] < endPoint[0] ? 1 : -1;

  // Sort the features by x-coordinate of the center
  intersectingFeatures.sort(function (a, b) {
    return direction * (a.x - b.x);
  });

  // Log the sorted features
  intersectingFeatures.forEach(function (item) {
    let originalStyle = item.feature.getStyle();
    let selectedfeature = item.feature;
    if (selectedfeature) {
      originalStyle[0].setFill(new Fill({ color: fillColor }));

      selectedfeature.setStyle(originalStyle);

      const cadastrallotno = selectedfeature.get("cadastrallotno");
      selectedfeature.set("section", section);
      selectedfeature.set("fillColor", fillColor);
      selectedfeature.set("startparcelno", startparcelno);
      let parcelno = selectedfeature.get("parcelno");
      // If the selected feature doesn't have a parcelno yet, or if it's an empty string, set it to a value
      if (!parcelno || parcelno === "") {
        parcelno = getLastParcelNo(features, section, startparcelno);
        const sectionstyle = selectedfeature.getStyle();
        sectionstyle[sectionstyle.length - 1]
          .getText()
          .setText(cadastrallotno + "- Parcel No." + parcelno);
        selectedfeature.set("parcelno", parcelno);
        5;
      } else {
        // If the selected feature already has a parcelno, set it to an empty string
        const sectionstyle = selectedfeature.getStyle();
        sectionstyle[sectionstyle.length - 1].getText().setText(cadastrallotno);
        originalStyle[0].setFill(
          new Fill({ color: "rgba(255, 204, 51, 0.2)" })
        );
        selectedfeature.setStyle(originalStyle);
        selectedfeature.set("parcelno", "");
      }
    }
  });
}

// function addSelectedFeatureToSectionListHandler(evt, params) {
//   const { section, startparcelno, fillColor } = params;
//   const drawlayer = getLayer("New Feature");
//   const drawSource = drawlayer.getSource();
//   const features = drawSource.getFeatures();
//   if (features.length === 0) {
//     alert("No features found");
//     return;
//   }
//   let selectedfeature = null;

//   map.forEachFeatureAtPixel(evt.pixel, function (f) {
//     originalStyle = f.getStyle();
//     selectedfeature = f;
//     return true;
//   });
//   if (selectedfeature) {
//     originalStyle[0].setFill(new Fill({ color: fillColor }));

//     selectedfeature.setStyle(originalStyle);

//     const cadastrallotno = selectedfeature.get("cadastrallotno");
//     selectedfeature.set("section", section);
//     let parcelno = selectedfeature.get("parcelno");
//     // If the selected feature doesn't have a parcelno yet, or if it's an empty string, set it to a value
//     if (!parcelno || parcelno === "") {
//       parcelno = getLastParcelNo(features, section, startparcelno);
//       const sectionstyle = selectedfeature.getStyle();
//       sectionstyle[sectionstyle.length - 1]
//         .getText()
//         .setText(cadastrallotno + "- Parcel No." + parcelno);
//       selectedfeature.set("parcelno", parcelno);
//       5;
//     } else {
//       // If the selected feature already has a parcelno, set it to an empty string
//       const sectionstyle = selectedfeature.getStyle();
//       sectionstyle[sectionstyle.length - 1].getText().setText(cadastrallotno);
//       originalStyle[0].setFill(new Fill({ color: "rgba(255, 204, 51, 0.2)" }));
//       selectedfeature.setStyle(originalStyle);
//       selectedfeature.set("parcelno", "");
//     }
//   }
// }

export function unbindParcelTaggingFeature() {
  // unByKey(map.on("pointermove", highlightSelectedFeatureHandler));
  unByKey(map.on("click", clickHandler));
  // getDrawingDataButton("multiple");
}

export function enableParcelTaggingFeature() {
  // const endparcelTaggingButton = document.createElement("button");
  // endparcelTaggingButton.textContent = "End Parcel Tagging";
  // endparcelTaggingButton.id = "endparcelTaggingButton";
  // endparcelTaggingButton.onclick = function () {
  //   unByKey(map.on("pointermove", highlightSelectedFeatureHandler));
  //   unByKey(map.on("click", tagSelectedFeatureHandler));
  //   //remove endparcelTaggingButton
  //   endparcelTaggingButton.remove();
  //   window.getGeoJSON();
  //   // window.OlModel.endTaggingParcel(featuredata);
  // };
  // // const buttonGroup = document.getElementById("buttonGroup");
  // // buttonGroup.appendChild(endparcelTaggingButton);
  //   map.on("pointermove", highlightSelectedFeatureHandler);
  //   map.on("click", tagSelectedFeatureHandler);
  // };
  // const buttonGroup = document.getElementById("buttonGroup");
  // buttonGroup.appendChild(parcelTaggingButton);
}

function highlightSelectedFeatureHandler(e) {
  const highlightStyle = new Style({
    stroke: new Stroke({
      color: "yellow",
      width: 3,
    }),
    fill: new Fill({
      color: "rgba(255, 255, 0, 0.1)",
    }),
  });

  if (selected !== null) {
    selected.setStyle(originalStyle);
    selected = null;
  }
  map.forEachFeatureAtPixel(e.pixel, function (f) {
    selected = f;
    originalStyle = f.getStyle();
    // const hoverstyle = originalStyle;
    // hoverstyle.push(highlightStyle);
    // console.log(originalStyle);
    const cadastrallotno = selected.get("cadastrallotno");
    let parcelno = selected.get("parcelno");
    const label = new Text({
      text: cadastrallotno + (parcelno ? "- Parcel No." + parcelno : ""),
      fill: new Fill({
        color: "#000000",
      }),
      stroke: new Stroke({
        color: "#ffffff",
        width: 3,
      }),
    });
    highlightStyle.setText(label);
    f.setStyle(highlightStyle);
    return true;
  });
}

function tagSelectedFeatureHandler(evt) {
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const features = drawSource.getFeatures();

  if (features.length === 0) {
    alert("No features found");
    return;
  }
  let selectedfeature = null;

  map.forEachFeatureAtPixel(evt.pixel, function (f) {
    f.setStyle(originalStyle);
    selectedfeature = f;
    return true;
  });
  if (selectedfeature) {
    const cadastrallotno = selectedfeature.get("cadastrallotno");
    const section = selectedfeature.get("section");
    const startparcelno = drawlayer.get(section);
    let parcelno = selectedfeature.get("parcelno");

    // If the selected feature doesn't have a parcelno yet, or if it's an empty string, set it to a value
    if (!parcelno || parcelno === "") {
      parcelno = getLastParcelNo(features, section, startparcelno);
      const sectionstyle = selectedfeature.getStyle();

      sectionstyle[sectionstyle.length - 1]
        .getText()
        .setText(cadastrallotno + "- Parcel No." + parcelno);
      selectedfeature.set("parcelno", parcelno);
    } else {
      // If the selected feature already has a parcelno, set it to an empty string
      const sectionstyle = selectedfeature.getStyle();
      sectionstyle[sectionstyle.length - 1].getText().setText(cadastrallotno);
      selectedfeature.set("parcelno", "");
    }
  }
}

// function getLastParcelNo(features, section, startparcelno) {
//   //get last no of features in section
//   let lastparcelno = startparcelno;
//   features.forEach((feature) => {
//     if (feature.get("section") === section) {
//       const cadastrallotno = feature.get("cadastrallotno");
//       let parcelno = feature.get("parcelno");
//       if (parcelno >= lastparcelno) {
//         parcelno++;
//         lastparcelno = parcelno;
//       }
//     }
//   });
//   return lastparcelno;
// }
