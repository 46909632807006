import { Map, View } from "ol";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { fromLonLat } from "ol/proj";


export const map = new Map({
  target: "map",
});

proj4.defs([
  [
    "EPSG:25391",
    "+proj=tmerc +lat_0=0 +lon_0=117 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25392",
    "+proj=tmerc +lat_0=0 +lon_0=119 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25392A",
    "+proj=tmerc +lat_0=0 +lon_0=119 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-79,-72,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25393",
    "+proj=tmerc +lat_0=0 +lon_0=121 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25393A",
    "+proj=tmerc +lat_0=0 +lon_0=121 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-79,-72,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25394",
    "+proj=tmerc +lat_0=0 +lon_0=123 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25394A",
    "+proj=tmerc +lat_0=0 +lon_0=123 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-79,-72,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25395",
    "+proj=tmerc +lat_0=0 +lon_0=125 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs +type=crs",
  ],
  [
    "EPSG:25395A",
    "+proj=tmerc +lat_0=0 +lon_0=125 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs +type=crs",
  ],
]);
register(proj4);

export { proj4 };

export let view = new View({
  center: fromLonLat([122.881, 12.8797]), // Longitude and latitude of the Philippines
  zoom: 6, // Adjust this value as needed to show the entire country
});

map.setView(view);
