import { Feature } from "ol";
import { LineString, Point, Polygon } from "ol/geom";
import { View } from "ol";
import { getCenter } from "ol/extent";
import { map, proj4 } from "./globals";

import {
  clearsource,
  addFeatureToLayer,
  addFeatureToLayerMultiple,
} from "./vectorlayer";
import { stylePolygonParcel, tieLineStyle, tiepointStyle } from "./mapstyle";
import { GeoJSON } from "ol/format";
import WKT from "ol/format/WKT";

export function plotsubdivision(
  parcelPolygonFeature,
  tieLineFeature,
  tiePointFeature,
  projection,
  sectionStyle
) {
  let polygonFeature = null;

  if (parcelPolygonFeature) {
    polygonFeature = parcelPolygonFeature;
    // console.log(sectionStyle);
    // polygonFeature.setStyle(sectionStyle);
    addFeatureToLayerMultiple("New Feature", polygonFeature);
  }

  const tieLineStringFeature = tieLineFeature;
  tieLineStringFeature.setStyle(tieLineStyle);
  addFeatureToLayerMultiple("Tie Line", tieLineStringFeature);

  const pointFeature = tiePointFeature;
  pointFeature.setStyle(tiepointStyle);
  addFeatureToLayerMultiple("Tie Point", pointFeature);

  const newprojectedview = new View({
    projection: projection,
    zoom: 5,
  });

  map.setView(newprojectedview);

 
  const focusfeature = polygonFeature ? polygonFeature : pointFeature;
  let featureextent = focusfeature.getGeometry().getExtent();
  let center = getCenter(featureextent);

  map.getView().setCenter(center);
  map.getView().fit(featureextent);
  let currentResolution = map.getView().getResolution();
  map.getView().setResolution(currentResolution * 1);

  map.render();
}


export function plotfeatures(
  parcelPolygonFeature,
  tieLineFeature,
  tiePointFeature,
  projection
) {

  let polygonFeature = null;

  if (parcelPolygonFeature) {
    polygonFeature = parcelPolygonFeature;
    polygonFeature.setStyle(stylePolygonParcel);
    addFeatureToLayer("New Feature", polygonFeature);
  }

  const tieLineStringFeature = tieLineFeature;
  tieLineStringFeature.setStyle(tieLineStyle);
  addFeatureToLayer("Tie Line", tieLineStringFeature);

  const pointFeature = tiePointFeature;
  pointFeature.setStyle(tiepointStyle);
  addFeatureToLayer("Tie Point", pointFeature);

  const newprojectedview = new View({
    projection: projection,
    zoom: 5,
  });

  map.setView(newprojectedview);

  const focusfeature = polygonFeature ? polygonFeature : pointFeature;
  let featureextent = focusfeature.getGeometry().getExtent();
  let center = getCenter(featureextent);

  map.getView().setCenter(center);
  map.getView().fit(featureextent);
  let currentResolution = map.getView().getResolution();
  map.getView().setResolution(currentResolution * 1);

  map.render();
}

export function generatecoords(tiepoint, technicaldescriptions) {
  if (tiepoint) {
    try {
      const projection = getprojection(tiepoint.zone);
      const coordinatesPRS92 = [
        parseFloat(tiepoint.ptmx),
        parseFloat(tiepoint.ptmy),
      ];

      //reproject coordinates to WGS84
      const coordinatesWGS84 = proj4(projection, "EPSG:4326", coordinatesPRS92);

      const coords = [coordinatesWGS84];

      technicaldescriptions.forEach((element) => {
        const td = {
          bearing: CalcBearing(
            element.ns,
            element.deg + element.min / 60,
            element.ew
          ),
          distance: element.distance,
        };

        const p = DestinationPoint(
          td.distance,
          td.bearing,
          coords[coords.length - 1]
        );
        coords.push([p.lon, p.lat]);
      });

      return {
        coords: coords,
        projection: projection
      };
    } catch (e) {
      console.error(e.message);
      alert(e.message);
    }
  } else {
    alert("NO TIE POINT");
    return null;
  }
}

export function generatefeature(coords, projection, technicaldescriptions) {
  const geoJSONFormat = new GeoJSON();
  const wktFormat = new WKT();
  //create tiepoint
  const point = new Point(coords[0]);
  point.transform("EPSG:4326", projection);
  const tiePointFeature = new Feature(point);

  //create tie line
  const tieLineString = new LineString(coords.slice(0, 2));
  tieLineString.transform("EPSG:4326", projection);

  const tieLineFeature = new Feature({
    type: "Line",
    geometry: tieLineString,
  });
  //create parcel
  let parcelPolygonFeature = null;
  let parcelgeojson = null;
  let parcelwkt = null;
  if (coords.length > 2) {
    const polygonCoords = [...coords.slice(1), coords[1]];
    const polygon = new Polygon([polygonCoords]);
    polygon.transform("EPSG:4326", projection);

    parcelPolygonFeature = new Feature({
      type: "Polygon",
      geometry: polygon,
      technicaldescriptions: technicaldescriptions.slice(1),
    });
    parcelgeojson = geoJSONFormat.writeFeatureObject(parcelPolygonFeature);
    parcelwkt = wktFormat.writeFeatureText(parcelPolygonFeature);
  }

  const response = {
    parcelwkt: parcelwkt,
    parcelgeojson: parcelgeojson,
    parcelPolygonFeature: parcelPolygonFeature,
    tieLineFeature: tieLineFeature,
    tiePointFeature: tiePointFeature,
  };
  return response;
}

export function getprojection(zone) {
  let projection = "EPSG:4326";
  zone = zone.trim();
  switch (zone) {
    case "1":
      projection = "EPSG:25391";
      break;
    case "2":
      projection = "EPSG:25392";
      break;
    case "2A":
      projection = "EPSG:25392A";
      break;
    case "3":
      projection = "EPSG:25393";
      break;
    case "3A":
      projection = "EPSG:25392A";
      break;
    case "4":
      projection = "EPSG:25394";
      break;
    case "4A":
      projection = "EPSG:25394A";
      break;
    case "5":
      projection = "EPSG:25395";
      break;
    case "5A":
      projection = "EPSG:25395A";
      break;
  }

  return projection;
}
export function DestinationPoint(distance, bearing, x) {
  var radius = 6371e3;
  var δ = Number(distance) / Number(radius);
  var θ = (Number(bearing) * Math.PI) / 180;

  var φ1 = (x[1] * Math.PI) / 180;
  var λ1 = (x[0] * Math.PI) / 180;

  var sinφ1 = Math.sin(φ1),
    cosφ1 = Math.cos(φ1);
  var sinδ = Math.sin(δ),
    cosδ = Math.cos(δ);
  var sinθ = Math.sin(θ),
    cosθ = Math.cos(θ);

  var sinφ2 = sinφ1 * cosδ + cosφ1 * sinδ * cosθ;
  var φ2 = Math.asin(sinφ2);
  var y = sinθ * sinδ * cosφ1;
  var x = cosδ - sinφ1 * sinφ2;
  var λ2 = λ1 + Math.atan2(y, x);

  return new LatLon(
    (φ2 * 180) / Math.PI,
    (((λ2 * 180) / Math.PI + 540) % 360) - 180
  );
}

export function CalcBearing(ns, numericAngle, ew) {
  var errUntrapped = -99; // error value for all miscellaneous errors
  var SurveyBearing2Angle;

  ns = ns.toLowerCase();
  ew = ew.toLowerCase();
  if (ns != "n" && ns != "s") {
    // error-check the NS value
    alert("The North/South parameter must be 'n' or 's'!");
    return; // bail out
  }

  if (ew != "e" && ew != "w") {
    // error-check the EW value
    alert("The East/West parameter must be 'e' or 'w'!");
    return; // bail out
  }

  if (numericAngle < 0 || numericAngle > 90) {
    // error-check the numeric portion
    alert("The numeric angle must be 0 <= theta <= 90!");
    return; // bail out
  }

  // handle each quadrant separately for the calculation
  switch (ns) {
    case "n":
      switch (ew) {
        case "e":
          SurveyBearing2Angle = 0 + numericAngle;
          break;
        case "w":
          SurveyBearing2Angle = 360 - numericAngle;
          break;
        default:
          SurveyBearing2Angle = errUntrapped;
          break;
      }
      break;
    case "s":
      switch (ew) {
        case "e":
          SurveyBearing2Angle = 180 - numericAngle;
          break;
        case "w":
          SurveyBearing2Angle = 180 + numericAngle;
          break;
        default:
          SurveyBearing2Angle = errUntrapped;
          break;
      }
      break;
    default:
      SurveyBearing2Angle = errUntrapped;
      break;
  }

  return SurveyBearing2Angle;
}
export function LatLon(lat, lon) {
  // allow instantiation without 'new'
  if (!(this instanceof LatLon)) return new LatLon(lat, lon);

  this.lat = Number(lat);
  this.lon = Number(lon);
}

export function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function toDegrees(radians) {
  return radians * (180 / Math.PI);
}

export function haversineDistance(coord1, coord2) {
  const R = 6371e3; // Radius of Earth in meters
  const lat1 = toRadians(coord1[1]);
  const lat2 = toRadians(coord2[1]);
  const deltaLat = toRadians(coord2[1] - coord1[1]);
  const deltaLon = toRadians(coord2[0] - coord1[0]);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

export function calculateAzimuth(coord1, coord2) {
  const lat1 = toRadians(coord1[1]);
  const lat2 = toRadians(coord2[1]);
  const deltaLon = toRadians(coord2[0] - coord1[0]);

  const y = Math.sin(deltaLon) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLon);
  let azimuth = toDegrees(Math.atan2(y, x));
  if (azimuth < 0) {
    azimuth += 360;
  }
  return azimuth;
}

export function azimuthToBearing(azimuth) {
  let ns, ew;
  let bearing = azimuth;

  if (bearing <= 90) {
    ns = "N";
    ew = "E";
  } else if (bearing <= 180) {
    ns = "S";
    ew = "E";
    bearing = 180 - bearing;
  } else if (bearing <= 270) {
    ns = "S";
    ew = "W";
    bearing = bearing - 180;
  } else {
    ns = "N";
    ew = "W";
    bearing = 360 - bearing;
  }

  const deg = Math.floor(bearing);
  const min = parseFloat(((bearing - deg) * 60).toFixed(2));

  return { ns, deg, min, ew };
}

export function generateTechnicalDescriptions(coordinates) {
  let technicalDescriptions = [];

  // Include the tie point
  const tiePoint = coordinates[0];
  const firstPoint = coordinates[1];
  const tieDistance = haversineDistance(tiePoint, firstPoint);
  const tieAzimuth = calculateAzimuth(tiePoint, firstPoint);
  const tieBearing = azimuthToBearing(tieAzimuth);

  technicalDescriptions.push({
    ...tieBearing,
    distance: parseFloat(tieDistance.toFixed(2)),
    frompoint: 0,
    topoint: 1,
  });

  for (let i = 1; i < coordinates.length - 1; i++) {
    const startPoint = coordinates[i];
    const endPoint = coordinates[i + 1];

    const distance = haversineDistance(startPoint, endPoint);
    const azimuth = calculateAzimuth(startPoint, endPoint);
    const bearing = azimuthToBearing(azimuth);

    let topoint = i + 1;
    if (i === coordinates.length - 2) {
      // Check if it's the last iteration
      topoint = 1;
    }

    technicalDescriptions.push({
      ...bearing,
      distance: parseFloat(distance.toFixed(2)), // Distance in meters
      frompoint: i,
      topoint: topoint,
    });
  }

  return technicalDescriptions;
}
